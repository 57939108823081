<template>
  <div id="MemberProduct">
    <img class="main" src="img/vip5.jpg" />
    <div class="bottom" @click="show = true;toifShow()">
      <!-- <img  src="img/eight.png"  /> -->
      <div>
        <div><span>会员</span><span>¥</span><span>{{getVipProduct.vipProduct ? priceNow :999}}</span><span>/<span style="text-decoration: line-through;">1299</span>年</span></div>
        <img  src="img/bottom1.png"  />
        <span>立即开通</span>
      </div>
    </div>
    <!-- <img  src="img/bottom.png"  /> -->
    <!-- 会员购买弹框 -->
    <van-popup v-model="show" get-container="#MemberProduct" style="background:none">
      <div class="productBuy">
        <div>
          <p>保观VIP会员</p>
          <div class="border">
            <div @click="checkPro(getVipProduct.vipProduct.product_id,getVipProduct.vipProduct.type,getVipProduct.vipProduct.id)" :class="{'active':type == getVipProduct.vipProduct.type}" v-if="getVipProduct.vipProduct" >
              <span>年卡</span>
              <span><span>¥</span>{{priceNow}}</span>
              <span style="text-decoration: line-through;">¥1299</span>
              <span class="red" v-if="inviteCode">受邀立减{{getVipProduct.vipProduct.reduce}}元</span>
            </div>
            <!-- <div style="margin-left:0.15rem" @click="checkPro(getVipProduct.experienceVip.product_id,getVipProduct.experienceVip.type,getVipProduct.experienceVip.id)" :class="{'active':type == getVipProduct.experienceVip.type}" v-if="getVipProduct.experienceStatus == 0 && getVipProduct.experienceVip">
              <span>{{getVipProduct.experienceVip.name}}</span>
              <span><span>¥</span>{{getVipProduct.experienceVip.price}}</span>
              <span>新用户体验会员</span>
              <span class="gray">仅限一次</span>
            </div> -->
          </div>
          <!-- <div v-if="inviteCode" class="towButtom">
            <span  @click="pay">立即开通</span>
            <span  @click="sharecode">立即转发</span>
          </div> -->
          <span  @click="pay">立即开通</span>
          <div class="exchange">
            <p><input v-model="inviteCode" placeholder="请输入优惠码" /></p>
            <span @click="conversion">验证</span>
          </div>
        </div>
        <div class="close">
          <span @click="show = false">
            <van-icon color="#fff" name="cross" style="font-size:0.2rem" />
          </span>
        </div>
      </div>
    </van-popup>
    <!-- 转发优惠码弹框 -->
    <van-popup v-model="showImg" get-container="#MemberProduct" style="background:none;width:75%" @close="closeImg">
      <img style="width:100%" :src="shareImg" />
    </van-popup>
  </div>
</template>
<script>
export default {
  name: 'MemberProduct',
   data(){
    return{
      show:false,
      isPay: 0,
      getVipProduct:{
       
      },
      priceNow:'',
      product_id:'',
      type:'',
      id:'',
      inviteCode:'',//邀请码
      showImg:false,
      shareImg:'',
      transmit:'',//预付款参数
    }
  },
  created(){
    this.inviteCode = this.$route.query.inviteCode  //邀请码
    this.Global.getShareConfig({
      title: '新锐会员  | 保观大咖会',
      desc: '在线分享、干货报告、线下活动、会员交流一起打包给你。'
    },this)
  },
  mounted(){
    if(this.$route.query.dhm){
      this.show = true
      this.toShow()
    }else if(this.$route.query.inviteCode){
      this.toShow()
    }
    if(this.$route.query.transmit){
      this.transmit = this.$route.query.transmit
    }else{
      this.transmit = ''
    }
  },
  methods:{
    // 显示弹框获取会员信息
    toifShow(){
      if(!this.$route.query.dhm && !this.$route.query.inviteCode){
        if(!this.inviteCode){
            this.axios({
            method: 'GET',
            url: '/vip/getVipProduct?inviteCode='+this.inviteCode,
            headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
            async:false
          }).then((res) => {
            if(res.data.code == 0){
              this.getVipProduct = res.data.data
              this.priceNow = this.getVipProduct.vipProduct.price
              this.getVipProduct.vipProduct.reduce = 0
              this.product_id = this.getVipProduct.vipProduct.product_id
              this.type = this.getVipProduct.vipProduct.type
              this.id = this.getVipProduct.vipProduct.id
              this.show = true
            }
          })
        }
      }
    },
    // 弹框
    toShow(){
      if(this.inviteCode){  //如果有验证码
        this.axios({
          method: 'GET',
          url: '/vip/getVipProduct?inviteCode='+this.inviteCode,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.getVipProduct = res.data.data
            this.getVipProduct.vipProduct.reduce = 0
            this.priceNow = res.data.data.vipProduct.price
            this.product_id = this.getVipProduct.vipProduct.product_id
            this.type = this.getVipProduct.vipProduct.type
            this.id = this.getVipProduct.vipProduct.id
            this.show = true

            this.axios({
              method: 'GET',
              url: '/vip/checkRedeemCode?inviteCode='+this.inviteCode+'&type=1',
              headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
            }).then((res) => {
              if(res.data.code == 0){
                this.$toast(res.data.data.msg)
                  this.priceNow = res.data.data.price //最终支付价格
                  this.getVipProduct.vipProduct.reduce =  res.data.data.discountPrice//优惠了多少的价格

                // if(res.data.data.is_discounts  == 1){ //优惠了价格
                //   this.priceNow = (this.getVipProduct.vipProduct.price*100 - res.data.data.price**100)/100
                //   this.getVipProduct.vipProduct.reduce = res.data.data.price
                // }else if(res.data.data.is_discounts  == 0){ //优惠后的价格
                //   this.priceNow = res.data.data.price //最终支付价格
                //   this.getVipProduct.vipProduct.reduce = (this.getVipProduct.vipProduct.price*100 - res.data.data.price*100)/100
                // }else if(res.data.data.is_discounts  == 2){ //自己的优惠码
                //   // this.priceNow = this.getVipProduct.vipProduct.price
                //   this.getVipProduct.vipProduct.reduce = 0
                // }
              }
            })
          }
        })
      }else{
        this.axios({
          method: 'GET',
          url: '/vip/getVipProduct?inviteCode='+this.inviteCode,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
          async:false
        }).then((res) => {
          if(res.data.code == 0){
            this.getVipProduct = res.data.data
            this.priceNow = this.getVipProduct.vipProduct.price
            this.getVipProduct.vipProduct.reduce = 0
            this.product_id = this.getVipProduct.vipProduct.product_id
            this.type = this.getVipProduct.vipProduct.type
            this.id = this.getVipProduct.vipProduct.id
            this.show = true
          }
        })
      }
    },
    // 如果存在邀请码  请求是否可以兑换  并且自动兑换填充
    // 验证 
    conversion(){
      if(this.inviteCode){
        this.toShow()
        // this.axios({
        //   method: 'GET',
        //   url: '/vip/checkRedeemCode?inviteCode='+this.inviteCode+'&type=1',
        //   headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        //   async:false
        // }).then((res) => {
        //   if(res.data.code == 0){
        //     this.$toast(res.data.data.msg)
        //     if(res.data.data.is_discounts  == 1){ //优惠了价格
        //       this.getVipProduct.vipProduct.price = this.getVipProduct.vipProduct.price - res.data.data.price
        //     }else{ //优惠后的价格
        //       this.getVipProduct.vipProduct.price = res.data.data.price
        //     }
            
        //   }
        // })
        // this.axios({
        //   method: 'GET',
        //   url: '/vip/conversionVip?code='+this.inviteCode,
        //   headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        // }).then((res) => {
        //   if(res.data.code == 0){
        //     this.$toast(res.data.data.msg)
        //     if(res.data.data.res){
        //       var _this = this;
        //       setTimeout(function(){
        //         _this.$router.push('/Home')
        //       },2000)
        //     }
        //   }else{
        //     this.$toast(res.data.message.code[0])
        //   }
        // })
      }else{
        this.$toast('兑换码不能为空')
      }
      
    },
    // 选中年卡活会员
    checkPro(product_id,type,id){
      this.product_id = product_id
      this.type = type
      this.id = id
    },
    // 分享弹框关系，购买弹框展示
    closeImg(){
      this.showImg = false
      this.show = true
    },
 
    pay(){
      this.axios({
        method: 'GET',
        url: '/payment/products?productId='+this.product_id+'&type='+this.type+'&invite_code='+this.inviteCode,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.prepay()
        }
      })
    },
    prepay(){
      if( !this.isPay ){
        this.isPay = 1
        this.axios({
          method: 'POST',
          url: '/payment/prepay',
          headers: {
            'Authorization': `Bearer ${this.Global.getCookie('token')}` 
          },
          data: {
            productId: this.id,
            invite_code: this.inviteCode,
            transmit:this.transmit
          }
        }).then( (res) => {
          if(res.data.code == 0){
            if(res.data.data.redirect){
              //免费
              this.$toast('购买成功！')
              setTimeout(() => {
                this.$router.push('/EditMessage')
              }, 1000)
            }else{
              this.callPay(res.data.data)
            }
          }else{
            this.$toast(res.data.message)
          }
        })
      }
    },
    // 微信调起支付
    callPay(res){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(res), false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(res)); 
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(res));
        }
      }else{
        this.onBridgeReady(res);
      }
    },
    onBridgeReady(data){
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', data.config,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$toast('购买成功！')
            setTimeout(() => {
              this.$router.push('/EditMessage?showQrCode=1')
            }, 1000)
          }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.isPay = 0
          }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            this.isPay = 0
            this.$toast('支付失败')
          }
      }); 
    },
  }
}
</script>
<style lang="less" scoped>
#MemberProduct{
  position: relative;
  .towButtom{
    margin: 0.2rem 0 0.15rem;
    >span{
      text-align: center;
      background: linear-gradient(90deg, #2E2E2E 0%, #494949 100%);
      height: 0.36rem;
      line-height: 0.36rem;
      border-radius: 0.1rem;
      color: #FFFFFF;
      font-size: 0.15rem;
      flex: 1;
    }
    >span:last-child{
      margin-left: 0.15rem;
    }
  }
  .exchange{
    padding-bottom: 0.1rem;
    display: flex;
    >p{
      flex: 1;
      >input{
        display: block;
        border-radius: 0.05rem 0 0 0.05rem;
        border: 1px solid #989AB1;
        text-indent: 0.05rem;
        height: 0.36rem;
        padding: 0;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
      }
    }
    >span{
      display: block;
      height: 0.36rem;
      line-height: 0.36rem;
      padding: 0 0.15rem;
      border-radius: 0 0.05rem 0.05rem 0;
      background: linear-gradient(90deg, #2E2E2E 0%, #494949 100%);
      color: #fff;
    }
  }
  .productBuy{
    >div:first-child{
      border-radius: 0.1rem;
      background: #FFFFFF;
      width: 2.73rem;
      padding: 0 0.2rem;
      box-sizing: border-box;
      // overflow: hidden;
      >p{
        color: #3D404F;
        padding: 0.15rem;
        text-align: center;
        font-size: 0.15rem;
      }
      >div{
        display: flex;
        justify-content: center;
        .active{
          border: 1px solid #BF8645;
          background: #FFF7EE;
        }
        >div{
          box-sizing:border-box;
          border: 1px solid #989AB1;
          border-radius: 0.05rem;
          width: 1.06rem;
          min-height: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          >span:first-child{
            color: #454545;
            font-size: 0.14rem;
            font-weight: 900;
            margin: 0.15rem 0 0.03rem;
          }
          >span:nth-child(2){
            color: #C39C73;
            font-size: 0.21rem;
            >span{
              font-size: 0.15rem;
              margin-right: 0.03rem;
            }
          }
          >span:nth-child(3){
            color: #989AB1;
            font-size: 0.12rem;
            margin-top: 0.05rem;
          }
          .gray{
            color: #989AB1;
            font-size: 0.12rem;
            margin-bottom: 0.1rem;
          }
          .red{
            color: #fff;
            font-size: 0.12rem;
            transform: scale(0.8);
            height: 0.22rem;
            line-height: 0.22rem;
            border-radius: 0.22rem;
            padding: 0 0.14rem;
            background: #FF4A4A;
            white-space: nowrap;
            margin-bottom: 0.1rem;
          }
        }
      }
      >span{
        display: block;
        margin: 0.2rem 0 0.15rem;
        text-align: center;
        background: linear-gradient(90deg, #2E2E2E 0%, #494949 100%);
        height: 0.36rem;
        line-height: 0.36rem;
        border-radius: 0.36rem;
        color: #FFFFFF;
        font-size: 0.16rem;
      }
    }
    .close{
      text-align: center;
      >span{
        width: 0.35rem;
        height: 0.35rem;
        background: #6e6f72;
        border-radius: 50%;
        margin-top: 0.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 0.2rem;
      }
    }
  }
  .bottom{
    >img{
      width: 0.68rem;
      left: 0.24rem;
      position: absolute;
      top: -0.12rem;
    }
    >div{
      >div{
        display: flex;
        background: linear-gradient(82deg, #E6D0B9 0%, #D9B791 100%);
        border-top-left-radius: 0.36rem;
        border-bottom-left-radius: 0.36rem;
        height: 0.36rem;
        flex: 1;
        padding-left: 0.23rem;
        align-items: baseline;
        >span{
          color: #111111;
        }
        >span:first-child{
          font-size: 0.16rem;
        }
        >span:nth-child(2){
          font-size: 0.15rem;
          margin-left: 0.07rem;
          font-weight: bold;
        }
        >span:nth-child(3){
          font-size: 0.21rem;
          font-weight: bold;
        }
        >span:nth-child(4){
          font-size: 0.12rem;
        }
      }
      >img{
        height: 0.36rem;
      }
      >span{
        width: 1.13rem;
        height: 0.36rem;
        text-align: center;
        color: #F6F7FD;
        font-size: 0.16rem;
        background: linear-gradient(270deg, #2E2E2E 0%, #494949 100%);
        border-top-right-radius: 0.36rem;
        border-bottom-right-radius: 0.36rem;
      }
      display: flex;
      align-items: center;
      height: 0.36rem;
      line-height: 0.36rem;
    }
    width: 100%;
    position:fixed;
    bottom: 0.2rem;
    padding: 0 0.15rem;
    box-sizing: border-box;
    max-width: 750px;
  }
  .main{
    width: 100%;
    padding-bottom: 0.5rem;
  }
}
</style>